import React from 'react';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';
import ContentArea from '../components/ContentArea';

const NotFoundPage = () => {
  const title = '404: Page not found';
  const excerpt = "Well I imagine that's a bit annoying? Seems the page you're looking for doesn't exist.";
  const html = '<p>Is it possible you typed in the wrong address? If you feel like this page should be accessible at this address then <a href="/contact">please let me know</a>. Alternatively, to get back on track, one of the following links may be of some use:</p><ul><li><a href="/">Go back to the home page</a></li><li><a href="/tags">Access all tags</a></li></ul>';

  return (
    <PageLayout>
      <MetaTags
        metaTitle={title}
        metaDescription={excerpt}
      />
      <Banner
        title={title}
        excerpt={excerpt}
        imgSrc='/images/not-even-sorry.jpg'
      />
      <ContentArea dangerouslySetInnerHTML={{ __html: html }} />
    </PageLayout>
  );
}

export default NotFoundPage;
